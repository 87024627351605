:root {
    /*? COLORS --------------------------------------------------------->  */
    --color-primary                                 :#212121;
    --color-primary-400                             :#4b4b4b;
    --color-primary-300                             :#a6a6a6;
    --color-primary-200                             :#bababa;
    --color-primary-100                             :#e5e5e5;

    --color-bg                                      :#f5f5f5;
    --color-bg-auth                                 :#fdfdfd;
    --color-container                               :#fdfdfd;
    --color-navbar                                  :#212121;
    --color-kpi-brand                               :#212121;
    --color-txt-kpi-brand                           :#fafafa;

    --color-txt-navbar                              :#fdfdfd;
    --color-sidebar                                 :#212121;
    --color-container-grid-locker                   :#212121;
    --color-label                                   :#4b4b4b;

    /*? STATUS */
    --color-danger                                  :#e50f0f;
    --color-danger-soft                             :#fce7e7;
    --color-warn                                    :#e08803;
    --color-warn-soft                               :#fcf3e6;
    --color-success                                 :#2ca04c;
    --color-success-soft                            :#dfffe2;
    --color-info                                    :#0076b9;
    --color-info-soft                               :#e6f3fa;
    --color-available                               :#18345d;
    --color-available-soft                          :#bfe2f5;
    --color-muted                                   :#828282;
    --color-muted-soft                              :#f0f0f0;

	 /*? STATUS */
	 --color-fuschia                                 :#961574;
	 --color-fuschia-soft                            :#f7daed;
	 --color-purple                                  :#6f36ae;
	 --color-purple-soft                             :#eadcfc;
	 --color-indigo                                  :#414bb3;
	 --color-indigo-soft                             :#dee0fa;
	 --color-turquoise                               :#115f70;
	 --color-turquoise-soft                          :#beebe7;
	 --color-blue                                    :#18345d;
	 --color-blue-soft                               :#bfe2f5;
	 --color-aqua                                    :#0076b9;
	 --color-aqua-soft                               :#e6f3fa;
	 --color-lime                                    :#0a6047;
	 --color-lime-soft                               :#c7ebd1;
	 --color-green                                   :#336113;
	 --color-green-soft                              :#d5f0b1;
	 --color-orange                                  :#903517;
	 --color-orange-soft                             :#fcddc7;
	 --color-red                                     :#a1160a;
	 --color-red-soft                                :#fadcd9;
	 --color-gray                                    :#3e5463;
	 --color-gray-soft                               :#dce3e8;

    /*? BUTTONS -----------------------> */
    /* PRIMARY */
    --color-btn-primary-default                     :#212121;
    --color-btn-primary-hover                       :#4b4b4b;
    --color-btn-primary-active                      :#797979;
	--color-btn-primary-disable                     :#a6a6a6;
    --color-btn-primary-txt                         :#fdfdfd;

    /* SECONDARY */
    --color-btn-secondary-default                   :#fdfdfd;
    --color-btn-secondary-hover                     :#e9e9e9;
    --color-btn-secondary-outline                   :#e5e5e5;
    --color-btn-secondary-active                    :#b7b7b7;
	--color-btn-secondary-disabled		            :#fdfdfd;
	--color-btn-secondary-txt		                :#212121;
    --color-btn-secondary-txt-disable               :#bababa;
    --color-btn-secondary-outline-disable           :#e5e5e5;


    /* TERTIARY */
    --color-btn-tertiary-default                    :#fdfdfd;
    --color-btn-tertiary-hover                      :#e9e9e9;
    --color-btn-tertiary-active                     :#b7b7b7;
	--color-btn-tertiary-disabled		            :#fdfdfd;
	--color-btn-tertiary-disable-txt                :#bababa;
	--color-btn-tertiary-txt	 	                :#212121;

    /*? DANGER -----------------------> */
    /* PRIMARY */
    --color-btn-primary-danger-default              :#e50f0f;
    --color-btn-primary-danger-hover                :#c30f0f;
    --color-btn-primary-danger-active               :#7e0808;
    --color-btn-primary-danger-disable              :#a7a7a7;
    --color-btn-primary-danger-txt                  :#fdfdfd;


    /* SECONDARY */
    --color-btn-secondary-danger-bg-default         :#fdfdfd;
    --color-btn-secondary-danger-outline            :#e50f0f;
    --color-btn-secondary-danger-outline-disable    :#e5e5e5;
    --color-btn-secondary-danger-bg-hover           :#ffe3e3;
    --color-btn-secondary-danger-bg-active          :#fcb1b1;
    --color-btn-secondary-danger-bg-disable         :#fdfdfd;
    --color-btn-secondary-danger-txt                :#e50f0f;
    --color-btn-secondary-danger-txt-disable        :#bababa;

    /*? INPUTS -----------------------> */
    --color-input-txt                               :#212121;
    --color-input-bg                                :#fdfdfd;
    --color-input-placeholder                       :#bababa;
    --color-input-txt-disable                       :#e5e5e5;
    --color-input-outline-default                   :#e5e5e5;
    --color-input-outline-focus                     :#212121;
    --color-input-outline-active                    :#212121;
    --color-input-outline-enable                    :#e5e5e5;
    --color-input-outline-danger                    :#e50f0f;
    --color-input-outline-disable                   :#e5e5e5;


    /*? BRAND COLORS */
    --color-primary-brand                           :#ffb840;
    --color-secondary-brand                         :#df0024;

    /*? CHARTS */
	--color-chart-100                               :#C5E1F1;
	--color-chart-200                               :#8AC6E7;
	--color-chart-300					            :#54ABDC;
	--color-chart-400					            :#339BD5;
	--color-chart-500					            :#0082CB;
	--color-chart-600					            :#0076B9;
	--color-chart-700					            :#005C90;
	--color-chart-800					            :#004870;
	--color-chart-900					            :#003755;

    /*? STATUS LOCKER GRID */
    --color-available                               :#363636;
    --color-occupied                                :#de8c00;
    --color-defective                               :#ed1f2d;
    --color-open                                    :#ffffff;
    --color-expired                                 :#fdf300;
    --color-schedule                                :#ab1ec2;
	--color-cancel									:#414bb3;

    /*? LOCKERS -----------------------> */
    /* LOCKERS COLOR */
    --color-locker-fill                             :#2f2f2f;
    --color-locker-stroke                           :#000;

    /*? MESSAGES -----------------------> */
    --color-message-error-bg                        :#fff0f0;
    --color-message-error-icon                      :#ffbfbf;
    --color-message-error-outline                   :#e50f0f;
    --color-message-error-title                     :#212121;
    --color-message-error-subtitle                  :#4b4b4b;

    --color-message-success-bg                      :#f3fff4;
    --color-message-success-icon                    :#c9ffcd;
    --color-message-success-outline                 :#2ca04c;
    --color-message-success-title                   :#212121;
    --color-message-success-subtitle                :#4b4b4b;

    /*? NAVBAR THEME -------------------> */
    --bg-light-selected                             :#bababa;
    --bg-dark-selected                              :transparent;
    --bg-hover-selected                             :#f0f0f0;

	/* ? SIDEBAR */
	--sidebar-bg 									:var(--silver-900);
	--sidebar-bg-hover								:var(--silver-800);
	--sidebar-bg-active								:var(--silver-700);
	--sidebar-text									:#FDFDFD;
	--sidebar-selected								:var(--silver-800);


    /*? PREFERENCES THEME */
    --outline-dark                                  :transparent;
    --outline-light                                 :#212121;
    /*? FONTS------------------------------------------------------------> */
    /* FONT FAMILY */
    --primary-font                                  :'Inter', sans-serif;

    /* FONT-SIZE */
    --font-bh1                                      :64px;
    --font-bh2                                      :48px;
    --font-bh3                                      :36px;
    --font-h1                                       :24px;
    --font-h2                                       :18px;
    --font-h3                                       :16px;
    --font-b1                                       :14px;
    --font-c1                                       :13px;
    --font-c2                                       :12px;
    --font-c3                                       :10px;

    /* FONT-WEIGHT  */
    --font-medium                                   :500;
    --font-semibold                                 :600;
    --font-bold                                     :700;

    /* LOGOS SIZE */
    /* AUTH */
    --logo-xxs                                      :40px;
    --logo-xs                                       :80px;
    --logo-sm                                       :90px;
    --logo-md                                       :100px;
    --logo-lg                                       :130px;
    --logo-width-auth                               :152px;

    /* NAVBAR */
    --gap-navbar-sm                                 :30px;
    --gap-navbar-md                                 :52px;

    /* NAVBAR */
    --size-navbar-sm                                :82px;
    --size-navbar-lg                                :82px;

    /* RADIUS */
    --radius-xxs                                    :6px;
    --radius-xs                                     :10px;
    --radius-sm                                     :15px;
    --radius-md                                     :20px;
    --radius-lg                                     :60px;

    /*? BUTTONS ---------------------------------------------------> */
    --btn-primary-height                            :42px;
    --btn-primary-height-sm                         :34px;

    --btn-primary-padding                           :0px 24px;
    --btn-primary-padding-sm                        :8px 18px;

    --btn-radius                                    :21px;

    /* ICONS */
    --size-icon-xs                                  :6px;
    --size-icon-sm                                  :15px;
    --size-icon-md                                  :16px;
    --size-icon-lg                                  :17px;
    --size-icon-xl                                  :32px;

    /* BTN MIN HEIGHT */
    --btn-minheight-xs                              :34px;
    --btn-minheight-sm                              :42px;

    /* BTN MAX HEIGHT */
    --btn-maxheight-xs                              :34px;
    --btn-maxheight-sm                              :42px;

    /*? CARD'S ---------------------------------------------------> */
    --height-card-sm                                 :131px;
    --height-card-md                                 :134px;
    --height-card-lg                                 :250px;
    --height-card-xlg                                :276px;
    --height-card-xl                                 :400px;
    --height-card-xxl                                :550px;
    /* PADDINGS */
    --padding-card-y                                 :12px;
    --padding-card-x                                 :18px;
    --padding-card-md-y                              :21px;
    --padding-card-md-x                              :32px;

    /*? INPUTS */
    --input-radius                                   :60px;
    --input-width-auth                               :369px;
    --input-width-xs                                 :180px;
    --input-width-sm                                 :268px;
    --input-width-md                                 :438px;
    --input-height-sm                                :42px;
    --input-padding                                  :10px 24px;

    /*? TAB --------------------------------------------------> */
    --padding-tab-sm                                :10px 16px;
    --padding-tab-lg                                :18px 32px;

    /*? GRID LOCKER  -----------------------------------------> */
    --width-locker-container                        :883px;
    --height-locker-container                       :568px;

    /*? LOCKER ID COLUMNS ------------------------------------> */
    --col-grid-locker                               :57%;
    --col-cards-locker-ID                           :16%;
    --col-map-locker-ID                             :24%;

   /*? IMAGES ------------------------------------------------> */
    /* LOGOS */
    --logo-auth                                     :url('../logos/logo-auth.svg') no-repeat;
    --img-auth                                      :url('../images/img-auth.png') no-repeat;
    /* --logo-navbar                                   :url('../logos/ikea/logo-navbar.svg') no-repeat; */
    --img-big-kpi                                   :url('../logos/oxxo/logo-kpi.svg') no-repeat;

    /*SIDEBAR ICONS*/
    --icon-sidebar-appearance                       :url('../icons/icon-sidebar-appearance.svg') no-repeat;
    --icon-sidebar-carriers                         :url('../icons/icon-sidebar-carriers.svg') no-repeat;
    --icon-sidebar-clients                          :url('../icons/icon-sidebar-clients.svg') no-repeat;
    --icon-sidebar-chevron                          :url('../icons/icon-sidebar-chevron.svg') no-repeat;
    --icon-sidebar-integrations                     :url('../icons/icon-sidebar-integrations.svg') no-repeat;
    --icon-sidebar-lockers                          :url('../icons/icon-sidebar-lockers.svg') no-repeat;
    --icon-sidebar-logout                           :url('../icons/icon-sidebar-logout.svg') no-repeat;
    --icon-sidebar-orders                           :url('../icons/icon-sidebar-orders.svg') no-repeat;
    --icon-sidebar-overview                         :url('../icons/icon-sidebar-overview.svg') no-repeat;
    --icon-sidebar-translate                        :url('../icons/icon-sidebar-translate.svg') no-repeat;
    --icon-sidebar-users                            :url('../icons/icon-sidebar-users.svg') no-repeat;
    --icon-sidebar-version                          :url('../icons/icon-sidebar-version.svg') no-repeat;
    --icon-sidebar-webhook                          :url('../icons/icon-sidebar-webhook.svg') no-repeat;
    --icon-sidebar-users                            :url('../icons/icon-sidebar-users.svg') no-repeat;
	--icon-sidebar-oxxo-to-oxxo						:url('../icons/icon-oxxo-to-oxxo.svg') no-repeat;

    /* ICONS */
	--icon-alert									:url('../icons/icon-alert.svg') no-repeat;
    --icon-appearance                               :url('../icons/icon-appearance.svg') no-repeat;
    --icon-arrow-down                               :url('../icons/icon-arrow-down.svg') no-repeat;
    --icon-arrow-up                                 :url('../icons/icon-arrow-up.svg') no-repeat;
    --icon-arrow-success                            :url('../icons/icon-arrow-success.svg') no-repeat;
    --icon-arrow-danger                             :url('../icons/icon-arrow-danger.svg') no-repeat;
    --icon-arrow-34                                 :url('../icons/icon-arrow-34.svg') no-repeat;
    --icon-bell                                     :url('../icons/icon-bells.svg') no-repeat;
    --icon-box-delivered                            :url('../icons/icon-log-delivered.svg') no-repeat;
    --icon-box-collected                            :url('../icons/icon-log-collected.svg') no-repeat;
    --icon-box-expired                              :url('../icons/icon-log-expired.svg') no-repeat;
    --icon-box-refuse                               :url('../icons/icon-log-refuse.svg') no-repeat;
    --icon-box-expired                              :url('../icons/icon-log-expired.svg') no-repeat;
    --icon-calendar                                 :url('../icons/icon-calendar.svg') no-repeat;
    --icon-chevron-down                             :url('../icons/icon-chevron-down.svg') no-repeat;
    --icon-chevron-activity                         :url('../icons/icon-chevron-activity.svg') no-repeat;
    --icon-chevron-down-danger                      :url('../icons/icon-chevron-down-danger.svg') no-repeat;
    --icon-chevron-down-export                      :url('../icons/icon-chevron-down-export-btn.svg') no-repeat;
    --icon-chevron-down-table                       :url('../icons/icon-chevron-down-table.svg') no-repeat;
    --icon-chevron-right                            :url('../icons/icon-chevron-right.svg') no-repeat;
    --icon-chevron-right-dropdown                   :url('../icons/icon-chevron-right-dropdown.svg') no-repeat;
    --icon-chevron-next                             :url('../icons/icon-chevron-next.svg') no-repeat;
    --icon-chevron-prev                             :url('../icons/icon-chevron-prev.svg') no-repeat;
    --icon-chevron-version                          :url('../icons/icon-chevron-down-version.svg') no-repeat;
    --icon-copy                                     :url('../icons/icon-copy.svg') no-repeat;
    --icon-en                                       :url('../icons/icon-flag-en.svg') no-repeat;
    --icon-es    									:url('../icons/icon-flag-es.svg') no-repeat;
	--icon-eye                                   	:url('../icons/icon-eye.svg') no-repeat;
	--icon-eye-off                                  :url('../icons/icon-eye-off.svg') no-repeat;
    --icon-file-download                            :url('../icons/icon-file-download.svg') no-repeat;
    --icon-funnel                                   :url('../icons/icon-funnel.svg') no-repeat;
    --icon-info-navbar                              :url('../icons/icon-info.svg') no-repeat;
    --icon-info                                     :url('../icons/icon-info.svg') no-repeat;
	--icon-info-red                                 :url('../icons/icon-info-red.svg') no-repeat;
    --icon-info-dropdown                            :url('../icons/icon-info-dropdown.svg') no-repeat;
    --icon-inte                                     :url('../icons/icon-inte.svg') no-repeat;
    --icon-key                                      :url('../icons/icon-key.svg') no-repeat;
    --icon-message-error                            :url('../icons/icon-message-error.svg') no-repeat;
    --icon-message-success                          :url('../icons/icon-message-success.svg') no-repeat;
    --icon-moon                                     :url('../icons/icon-moon.svg') no-repeat;
    --icon-logout                                   :url('../icons/icon-logout.svg') no-repeat;
    --icon-plus                                     :url('../icons/icon-plus.svg') no-repeat;
    --icon-save                                     :url('../icons/icon-save.svg') no-repeat;
    --icon-search                                   :url('../../assets/theme/light/icons/icon-search.svg') no-repeat;
    --icon-settings                                 :url('../icons/icon-settings.svg') no-repeat;
    --icon-star-fill                                :url('../icons/icon-star-fill.svg') no-repeat;
    --icon-star-outline                             :url('../icons/icon-star-outline.svg') no-repeat;
    --icon-sun                                      :url('../icons/icon-sun.svg') no-repeat;
    --icon-translate                                :url('../icons/icon-translate.svg') no-repeat;
    --icon-upload                                   :url('../icons/icon-upload.svg') no-repeat;
    --icon-update                                   :url('../icons/icon-update.svg') no-repeat;
    --icon-user-collected                           :url('../icons/icon-user-collected.svg') no-repeat;
    --icon-user-delivery                            :url('../icons/icon-user-delivery.svg') no-repeat;
    --icon-user-credentials                         :url('../icons/icon-user-credentials.svg') no-repeat;
    --icon-user-master-code                         :url('../icons/icon-user-master-code.svg') no-repeat;
    --icon-view                                     :url('../icons/icon-view.svg') no-repeat;
	--icon-times									:url('../icons/icon-times.svg') no-repeat;
	--icon-vivipost									:url('../icons/icon-vivipost.svg') no-repeat;
    --img-dark                                      :url('../images/img-dark-mode.svg') no-repeat;
    --img-light                                     :url('../images/img-light-mode.svg') no-repeat;

    /*? TRANSITIONS -------------------------------------> */
    --tran-02                           : all 0.2s ease;
    --tran-03                           : all 0.3s ease;
    --tran-04                           : all 0.4s ease;
    --tran-05                           : all 0.5s ease;

    /*? SPACING -------------------------------------> */
    --spacing-xs                        :18px;
    --spacing-sm                        :24px;
    --spacing-md                        :30px;
    --spacing-lg                        :68px;
    --spacing-1                         :4px;
    --spacing-2                         :8px;
    --spacing-3                         :12px;
    --spacing-4                         :16px;
    --spacing-5                         :24px;
    --spacing-6                         :32px;
    --spacing-7                         :40px;
    --spacing-8                         :48px;
    --spacing-9                         :56px;
    --spacing-10                        :64px;
    --spacing-11                        :72px;
    --spacing-12                        :80px;
    --spacing-13                        :96px;
    --spacing-14                        :104px;
    --spacing-15                        :120px;
    --spacing-16                        :160px;

    /*? RESPONSIVE -----------------------------------------> */
    /* CONTAINERS */
    --container-width                   :82.45%;
    --container-max-width               :1550px;
    --container-auth-lg                 :466px;

    /*? LOCKER ID COLUMNS -------------------> */
    --col-grid-locker                   :57%;
    --col-cards-locker-ID               :16%;
    --col-map-locker-ID                 :24%;
}

.dark-mode {
    /*? COLORS --------------------------------------------------------->  */
    --color-primary                                 :#fdfdfd;
    --color-primary-400                             :#bababa;
    --color-primary-300                             :#a6a6a6;
    --color-primary-200                             :#4B4B4B;
    --color-primary-100                             :#e5e5e5;

    --color-bg                                      :#212121;
    --color-bg-auth                                 :#212121;
    --color-container                               :#2d2d2d;
    --color-navbar                                  :#2d2d2d;
    --color-kpi-brand                               :#4b4b4b;
    --color-txt-kpi-brand                           :#fdfdfd;

    --color-txt-navbar                              :#fdfdfd;
    --color-sidebar                                 :#2d2d2d;
    --color-container-grid-locker                   :#4b4b4b;
    --color-label                                   :#a6a6a6;

    /*? STATUS */
    --color-danger                                  :#b02329;
    --color-danger-soft                             :#ffc7c7;
    --color-warn                                    :#bf790e;
    --color-warn-soft                               :#f5ddba;
    --color-success                                 :#2e8345;
    --color-success-soft                            :#cbf8cf;
    --color-info                                    :#0f6ca0;
    --color-info-soft                               :#b2d6e9;
    --color-available                               :#18345d;
    --color-available-soft                          :#b6cbd7;
    --color-muted                                   :#4f4d4d;
    --color-muted-soft                              :#c7c7c7;

	--color-fuschia                                 :#ff8dc6;
    --color-fuschia-soft                            :#523c4b;
    --color-purple                                  :#BF8DFF;
    --color-purple-soft                             :#514463;
    --color-indigo                                  :#91abff;
    --color-indigo-soft                             :#36394e;
    --color-turquoise                               :#59Bfd2;
    --color-turquoise-soft                          :#0d4744;
    --color-blue                                    :#afd2fc;
    --color-blue-soft                               :#144263;
    --color-aqua                                    :#49beA9;
    --color-aqua-soft                               :#344d58;
    --color-lime                                    :#3fc85d;
    --color-lime-soft                               :#436950;
    --color-green                                   :#265108;
    --color-green-soft                              :#205030;
    --color-orange                                  :#ef8656;
    --color-orange-soft                             :#493d33;
    --color-red                                     :#ef5656;
    --color-red-soft                                :#502f2f;
    --color-gray                                    :#a1a1a1;
    --color-gray-soft                               :#44484b;

    /*? BUTTONS -----------------------> */
    /* PRIMARY */
    --color-btn-primary-default                     :#4b4b4b;
    --color-btn-primary-hover                       :#212121;
    --color-btn-primary-active                      :#797979;
    --color-btn-primary-disable                     :#a6a6a6;
    --color-btn-primary-txt                         :#fdfdfd;

    /* SECONDARY */
    --color-btn-secondary-default                   :#2d2d2d;
    --color-btn-secondary-hover                     :#6c6c6c;
    --color-btn-secondary-outline                   :#4b4b4b;
    --color-btn-secondary-active                    :#a6a6a6;
    --color-btn-secondary-disabled		            :#2d2d2d;
    --color-btn-secondary-txt		                :#fdfdfd;
    --color-btn-secondary-txt-disable               :#4b4b4b;
    --color-btn-secondary-outline-disable           :#4b4b4b;

    /* TERTIARY */
    --color-btn-tertiary-default                    :#fdfdfd;
    --color-btn-tertiary-hover                      :#e9e9e9;
    --color-btn-tertiary-active                     :#b7b7b7;
    --color-btn-tertiary-disabled		            :#fdfdfd;
    --color-btn-tertiary-txt-disable                :#bababa;
    --color-btn-tertiary-txt	 	                :#212121;

    /*? DANGER -----------------------> */
    /* PRIMARY */
    --color-btn-primary-danger-default              :#e50f0f;
    --color-btn-primary-danger-hover                :#c30f0f;
    --color-btn-primary-danger-active               :#7e0808;
    --color-btn-primary-danger-disable              :#a7a7a7;
    --color-btn-primary-danger-txt                  :#fdfdfd;

    /* SECONDARY */
    --color-btn-secondary-danger-bg-default         :#2d2d2d;
    --color-btn-secondary-danger-outline            :#e50f0f;
    --color-btn-secondary-danger-outline-disable    :#e5e5e5;
    --color-btn-secondary-danger-bg-hover           :#dfbdbd;
    --color-btn-secondary-danger-bg-active          :#f98d8d;
    --color-btn-secondary-danger-bg-disable         :#fdfdfd;
    --color-btn-secondary-danger-txt                :#e50f0f;
    --color-btn-secondary-danger-txt-disable        :#bababa;

    /*? INPUTS -----------------------> */
    --color-input-txt                               :#fdfdfd;
    --color-input-bg                                :#2d2d2d;
    --color-input-placeholder                       :#4b4b4b;
    --color-input-txt-disable                       :#4b4b4b;
    --color-input-outline-default                   :#4d4d4d;
    --color-input-outline-focus                     :#fdfdfd;
    --color-input-outline-active                    :#4b4b4b;
    --color-input-outline-enable                    :#4b4b4b;
    --color-input-outline-danger                    :#f95252;
    --color-input-outline-disable                   :#4b4b4b;

    /*? BRAND COLORS */
    --color-primary-brand                           :#ffb840;
    --color-secondary-brand                         :#df0024;

    /*? CHARTS */
    --color-chart-100                               :#e6eff4;
    --color-chart-200                               :#b1ccdb;
    --color-chart-300					            :#8bb3ca;
    --color-chart-400					            :#5590b2;
    --color-chart-500					            :#357ba3;
    --color-chart-600					            :#025a8c;
    --color-chart-700					            :#02527f;
    --color-chart-800					            :#014063;
    --color-chart-900					            :#01324d;

    /*? STATUS LOCKER GRID */
    --color-available                               :#363636;
    --color-occupied                                :#de8c00;
    --color-defective                               :#ed1f2d;
    --color-open                                    :#ffffff;
    --color-expired                                 :#fdf300;
    --color-schedule                                :#ab1ec2;
	--color-cancel									:#414bb3;

    /*? LOCKERS -----------------------> */
    /* LOCKERS COLOR */
    --color-locker-fill                             :#212121;
    --color-locker-stroke                           :#000;

    /*? MESSAGES -----------------------> */
    --color-message-error-bg                         :#ffe3e3;
    --color-message-error-icon                       :#ffbfbf;
    --color-message-error-outline                    :#b02329;
    --color-message-error-title                      :#212121;
    --color-message-error-subtitle                   :#4b4b4b;

    --color-message-success-bg                       :#d2ffd6;
    --color-message-success-icon                     :#9dffa5;
    --color-message-success-outline                  :#2e8345;
    --color-message-success-title                    :#212121;
    --color-message-success-subtitle                 :#4b4b4b;

    /*? NAVBAR THEME -------------------> */
    --bg-light-selected                             :#bababa;
    --bg-dark-selected                              :transparent;
    --bg-hover-selected                             :#f0f0f0;

    /*? PREFERENCES THEME */
    --outline-dark                                  :transparent;
    --outline-light                                 :#212121;

	/*? SIDEBAR THEME  */
	--sidebar-bg 									:var(--silver-900);
	--sidebar-bg-hover								:var(--silver-800);
	--sidebar-bg-active								:var(--silver-700);
	--sidebar-text									:#FDFDFD;
	--sidebar-selected								:var(--silver-800);
    /*? FONTS------------------------------------------------------------> */
    /* FONT FAMILY */
    --primary-font                                  :'Inter', sans-serif;

    /* FONT-SIZE */
    --font-bh1                                      :64px;
    --font-bh2                                      :48px;
    --font-bh3                                      :36px;
    --font-h1                                       :24px;
    --font-h2                                       :18px;
    --font-h3                                       :16px;
    --font-b1                                       :14px;
    --font-c1                                       :13px;
    --font-c2                                       :12px;
    --font-c3                                       :10px;

    /* FONT-WEIGHT  */
    --font-medium                                   :500;
    --font-semibold                                 :600;
    --font-bold                                     :700;

    /* LOGOS SIZE */
    /* AUTH */
    --logo-xxs                                      :40px;
    --logo-xs                                       :80px;
    --logo-sm                                       :90px;
    --logo-md                                       :100px;
    --logo-lg                                       :130px;
    --logo-width-auth                               :152px;

    /* NAVBAR */
    --gap-navbar-sm                                 :30px;
    --gap-navbar-md                                 :52px;

    /* NAVBAR */
    --size-navbar-sm                                :82px;
    --size-navbar-lg                                :82px;

    /* RADIUS */
    --radius-xxs                                    :6px;
    --radius-xs                                     :10px;
    --radius-sm                                     :15px;
    --radius-md                                     :20px;
    --radius-lg                                     :60px;

    /*? BUTTONS ---------------------------------------------------> */
    --btn-primary-height                            :42px;
    --btn-primary-height-sm                         :34px;

    --btn-primary-padding                           :0px 24px;
    --btn-primary-padding-sm                        :8px 18px;

    --btn-radius                                    :21px;

    /* ICONS */
    --size-icon-xs                                  :6px;
    --size-icon-sm                                  :15px;
    --size-icon-md                                  :16px;
    --size-icon-lg                                  :17px;
    --size-icon-xl                                  :32px;

    /* BTN MIN HEIGHT */
    --btn-minheight-xs                              :34px;
    --btn-minheight-sm                              :42px;

    /* BTN MAX HEIGHT */
    --btn-maxheight-xs                              :34px;
    --btn-maxheight-sm                              :42px;

    /*? CARD'S ---------------------------------------------------> */
    --height-card-sm                                 :131px;
    --height-card-md                                 :134px;
    --height-card-lg                                 :250px;
    --height-card-xlg                                :276px;
    --height-card-xl                                 :400px;
    --height-card-xxl                                :550px;
    /* PADDINGS */
    --padding-card-y                                 :12px;
    --padding-card-x                                 :18px;
    --padding-card-md-y                              :21px;
    --padding-card-md-x                              :32px;

    /*? INPUTS */
    --input-radius                                   :60px;
    --input-width-auth                               :369px;
    --input-width-xs                                 :180px;
    --input-width-sm                                 :268px;
    --input-width-md                                 :438px;
    --input-height-sm                                :42px;
    --input-padding                                  :10px 24px;

    /*? TAB --------------------------------------------------> */
    --padding-tab-sm                                :10px 16px;
    --padding-tab-lg                                :18px 32px;

    /*? GRID LOCKER  -----------------------------------------> */
    --width-locker-container                        :883px;
    --height-locker-container                       :568px;

    /*? LOCKER ID COLUMNS ------------------------------------> */
    --col-grid-locker                               :57%;
    --col-cards-locker-ID                           :16%;
    --col-map-locker-ID                             :24%;

   /*? IMAGES ------------------------------------------------> */
   --logo-auth                                     :url('../logos/logo-auth.svg') no-repeat;
   --img-auth                                      :url('../images/img-auth.png') no-repeat;
   /* --logo-navbar                                   :url('../logos/ikea/logo-navbar.svg') no-repeat; */
   --img-big-kpi                                   :url('../logos/oxxo/logo-kpi.svg') no-repeat;

    /*SIDEBAR ICONS*/

    --icon-sidebar-appearance                       :url('../icons/icon-sidebar-appearance.svg') no-repeat;
    --icon-sidebar-carriers                         :url('../icons/icon-sidebar-carriers.svg') no-repeat;
    --icon-sidebar-clients                          :url('../icons/icon-sidebar-clients.svg') no-repeat;
    --icon-sidebar-chevron                          :url('../icons/icon-sidebar-chevron.svg') no-repeat;
    --icon-sidebar-integrations                     :url('../icons/icon-sidebar-integrations.svg') no-repeat;
    --icon-sidebar-lockers                          :url('../icons/icon-sidebar-lockers.svg') no-repeat;
    --icon-sidebar-logout                           :url('../icons/icon-sidebar-logout.svg') no-repeat;
    --icon-sidebar-orders                           :url('../icons/icon-sidebar-orders.svg') no-repeat;
    --icon-sidebar-overview                         :url('../icons/icon-sidebar-overview.svg') no-repeat;
    --icon-sidebar-translate                        :url('../icons/icon-sidebar-translate.svg') no-repeat;
    --icon-sidebar-users                            :url('../icons/icon-sidebar-users.svg') no-repeat;
    --icon-sidebar-version                          :url('../icons/icon-sidebar-version.svg') no-repeat;
    --icon-sidebar-webhook                          :url('../icons/icon-sidebar-webhook.svg') no-repeat;
    --icon-sidebar-users                            :url('../icons/icon-sidebar-users.svg') no-repeat;

    /* ICONS */
	--icon-alert									:url('../icons/dark-mode-icons/icon-alert.svg') no-repeat;
    --icon-appearance                               :url('../icons/dark-mode-icons/icon-appearance-dark.svg') no-repeat;
    --icon-arrow-down                               :url('../icons/dark-mode-icons/icon-arrow-down-dark.svg') no-repeat;
    --icon-arrow-up                                 :url('../icons/dark-mode-icons/icon-arrow-up-dark.svg') no-repeat;
    --icon-arrow-success                            :url('../icons/dark-mode-icons/icon-arrow-success-dark.svg') no-repeat;
    --icon-arrow-danger                             :url('../icons/dark-mode-icons/icon-arrow-danger-dark.svg') no-repeat;
    --icon-arrow-34                                 :url('../icons/dark-mode-icons/icon-arrow-34-dark.svg') no-repeat;
    --icon-bell                                     :url('../icons/dark-mode-icons/icon-bells-dark.svg') no-repeat;
    --icon-box-delivered                            :url('../icons/dark-mode-icons/icon-log-delivered-dark.svg') no-repeat;
    --icon-box-collected                            :url('../icons/dark-mode-icons/icon-log-collected-dark.svg') no-repeat;
    --icon-box-expired                              :url('../icons/dark-mode-icons/icon-log-expired-dark.svg') no-repeat;
    --icon-box-refuse                               :url('../icons/dark-mode-icons/icon-log-refuse-dark.svg') no-repeat;
    --icon-box-expired                              :url('../icons/dark-mode-icons/icon-log-expired-dark.svg') no-repeat;
    --icon-calendar                                 :url('../icons/dark-mode-icons/icon-calendar-dark.svg') no-repeat;
    --icon-chevron-down                             :url('../icons/dark-mode-icons/icon-chevron-down-dark.svg') no-repeat;
    --icon-chevron-activity                         :url('../icons/dark-mode-icons/icon-chevron-activity-dark.svg') no-repeat;
    --icon-chevron-down-danger                      :url('../icons/dark-mode-icons/icon-chevron-down-danger-dark.svg') no-repeat;
    --icon-chevron-down-export                      :url('../icons/dark-mode-icons/icon-chevron-down-export-btn-dark.svg') no-repeat;
    --icon-chevron-down-table                       :url('../icons/dark-mode-icons/icon-chevron-down-table-dark.svg') no-repeat;
    --icon-chevron-right                            :url('../icons/dark-mode-icons/icon-chevron-right-dark.svg') no-repeat;
    --icon-chevron-right-dropdown                   :url('../icons/dark-mode-icons/icon-chevron-right-dropdown-dark.svg') no-repeat;
    --icon-chevron-next                             :url('../icons/dark-mode-icons/icon-chevron-next-dark.svg') no-repeat;
    --icon-chevron-prev                             :url('../icons/dark-mode-icons/icon-chevron-prev-dark.svg') no-repeat;
    --icon-chevron-version                          :url('../icons/dark-mode-icons/icon-chevron-down-version-dark.svg') no-repeat;
    --icon-copy                                     :url('../icons/dark-mode-icons/icon-copy-dark.svg') no-repeat;
    --icon-en                                       :url('../icons/dark-mode-icons/icon-flag-en-dark.svg') no-repeat;
    --icon-es                                       :url('../icons/dark-mode-icons/icon-flag-es-dark.svg') no-repeat;
    --icon-file-download                            :url('../icons/dark-mode-icons/icon-file-download-dark.svg') no-repeat;
    --icon-funnel                                   :url('../icons/dark-mode-icons/icon-funnel-dark.svg') no-repeat;
    --icon-info-navbar                              :url('../icons/dark-mode-icons/icon-info-dark.svg') no-repeat;
    --icon-info                                     :url('../icons/dark-mode-icons/icon-info-dark.svg') no-repeat;
	--icon-info-red                                 :url('../icons/icon-info-red.svg') no-repeat;
    --icon-info-dropdown                            :url('../icons/dark-mode-icons/icon-info-dropdown-dark.svg') no-repeat;
    --icon-inte                                     :url('../icons/dark-mode-icons/icon-inte-dark.svg') no-repeat;
    --icon-key                                      :url('../icons/dark-mode-icons/icon-key-dark.svg') no-repeat;
    --icon-message-error                            :url('../icons/dark-mode-icons/icon-message-error-dark.svg') no-repeat;
    --icon-message-success                          :url('../icons/dark-mode-icons/icon-message-success-dark.svg') no-repeat;
    --icon-moon                                     :url('../icons/dark-mode-icons/icon-moon-dark.svg') no-repeat;
    --icon-logout                                   :url('../icons/dark-mode-icons/icon-logout-dark.svg') no-repeat;
    --icon-plus                                     :url('../icons/dark-mode-icons/icon-plus-dark.svg') no-repeat;
    --icon-save                                     :url('../icons/dark-mode-icons/icon-save-dark.svg') no-repeat;
    --icon-search                                   :url('../../assets/theme/dark/icons/icon-search.svg') no-repeat;
    --icon-settings                                 :url('../icons/dark-mode-icons/icon-settings-dark.svg') no-repeat;
    --icon-star-fill                                :url('../icons/dark-mode-icons/icon-star-fill-dark.svg') no-repeat;
    --icon-star-outline                             :url('../icons/dark-mode-icons/icon-star-outline-dark.svg') no-repeat;
    --icon-sun                                      :url('../icons/dark-mode-icons/icon-sun-dark.svg') no-repeat;
    --icon-translate                                :url('../icons/dark-mode-icons/icon-translate-dark.svg') no-repeat;
    --icon-upload                                   :url('../icons/dark-mode-icons/icon-upload-dark.svg') no-repeat;
    --icon-update                                   :url('../icons/dark-mode-icons/icon-update-dark.svg') no-repeat;
    --icon-user-collected                           :url('../icons/dark-mode-icons/icon-user-collected-dark.svg') no-repeat;
    --icon-user-delivery                            :url('../icons/dark-mode-icons/icon-user-delivery-dark.svg') no-repeat;
    --icon-user-credentials                         :url('../icons/dark-mode-icons/icon-user-credentials-dark.svg') no-repeat;
    --icon-user-master-code                         :url('../icons/dark-mode-icons/icon-user-master-code-dark.svg') no-repeat;
    --icon-view                                     :url('../icons/dark-mode-icons/icon-view-dark.svg') no-repeat;
	--icon-times									:url('../icons/dark-mode-icons/icon-times-dark.svg') no-repeat;
	--icon-vivipost									:url('../icons/dark-mode-icons/icon-vivipost.svg') no-repeat;
    --img-dark                                      :url('../images/img-dark-mode.svg') no-repeat;
    --img-light                                     :url('../images/img-light-mode.svg') no-repeat;


    /*? TRANSITIONS -------------------------------------> */
    --tran-02                           : all 0.2s ease;
    --tran-03                           : all 0.3s ease;
    --tran-04                           : all 0.4s ease;
    --tran-05                           : all 0.5s ease;

    /*? SPACING -------------------------------------> */
    --spacing-xs                        :18px;
    --spacing-sm                        :24px;
    --spacing-md                        :30px;
    --spacing-lg                        :68px;
    --spacing-1                         :4px;
    --spacing-2                         :8px;
    --spacing-3                         :12px;
    --spacing-4                         :16px;
    --spacing-5                         :24px;
    --spacing-6                         :32px;
    --spacing-7                         :40px;
    --spacing-8                         :48px;
    --spacing-9                         :56px;
    --spacing-10                        :64px;
    --spacing-11                        :72px;
    --spacing-12                        :80px;
    --spacing-13                        :96px;
    --spacing-14                        :104px;
    --spacing-15                        :120px;
    --spacing-16                        :160px;

    /*? RESPONSIVE -----------------------------------------> */
    /* CONTAINERS */
    --container-width                   :82.45%;
    --container-max-width               :1550px;
    --container-auth-lg                 :466px;

    /*? NAVBAR THEME -------------------> */
    --bg-light-selected                 :transparent;
    --bg-dark-selected                  :#4b4b4b;
    --bg-hover-selected                 :#4b4b4b;

}
