:root {
    /* ? PRIMITIVES -------------------------------------------------------------------->*/
    /* ? Primary */
    /* * Primary / Grey */
    --gray-25                                                   :#FCFCFD;
    --gray-50                                                   :#F9FaFB;
    --gray-100                                                  :#F2F4F7;
    --gray-200                                                  :#EAECF0;
    --gray-300                                                  :#D0D5DD;
    --gray-400                                                  :#98A2B3;
    --gray-500                                                  :#667085;
    --gray-600                                                  :#475467;
    --gray-700                                                  :#344054;
    --gray-800                                                  :#182230;
    --gray-900                                                  :#101828;
    --gray-950                                                  :#0C111D;
    /* * Primary / Brand */
    --brand-25                                                  :#EEF4FB;
    --brand-50                                                  :#E9F1FA;
    --brand-100                                                 :#BBD3EF;
    --brand-200                                                 :#9BBEE7;
    --brand-300                                                 :#6DA1DC;
    --brand-400                                                 :#518ED5;
    --brand-500                                                 :#246DC1;
    --brand-600                                                 :#2269BB;
    --brand-700                                                 :#1B4F8B;
    --brand-800                                                 :#143F70;
    --brand-900                                                 :#123C6B;
    --brand-950                                                 :#051A32;
    /* * Primary / Success */
    --success-25                                                :#F6FEF9;
    --success-50                                                :#ECFDF3;
    --success-100                                               :#DCFAE6;
    --success-200                                               :#A9EFC5;
    --success-300                                               :#75E0A7;
    --success-400                                               :#47CD89;
    --success-500                                               :#17B26A;
    --success-600                                               :#079455;
    --success-700                                               :#067647;
    --success-800                                               :#085D3A;
    --success-900                                               :#074D31;
    --success-950                                               :#053321;
    /* * Primary / Danger */
    --danger-25                                                 :#FFFBFA;
    --danger-50                                                 :#FEF3F2;
    --danger-100                                                :#FEE4E2;
    --danger-200                                                :#FECDCA;
    --danger-300                                                :#FDA29B;
    --danger-400                                                :#F97066;
    --danger-500                                                :#F04438;
    --danger-600                                                :#D92D20;
    --danger-700                                                :#B42318;
    --danger-800                                                :#912018;
    --danger-900                                                :#7A1A1A;
    --danger-950                                                :#55160C;
    /* * Primary / Warning */
    --warning-25                                                :#FFFCF5;
    --warning-50                                                :#FFFAEB;
    --warning-100                                               :#FEF0C7;
    --warning-200                                               :#FEDF89;
    --warning-300                                               :#FEC84B;
    --warning-400                                               :#FDB022;
    --warning-500                                               :#F79009;
    --warning-600                                               :#DC6803;
    --warning-700                                               :#B54708;
    --warning-800                                               :#93370D;
    --warning-900                                               :#7A2E0E;
    --warning-950                                               :#4E1D09;
    /* ? Utility */
    /* * Utility / Silver */
    --silver-25                                                 :#FCFCFC;
    --silver-50                                                 :#FAFAFA;
    --silver-100                                                :#F5F5F5;
    --silver-200                                                :#E5E5E5;
    --silver-300                                                :#D6D6D6;
    --silver-400                                                :#A3A3A3;
    --silver-500                                                :#737373;
    --silver-600                                                :#525252;
    --silver-700                                                :#424242;
    --silver-800                                                :#333333;
    --silver-900                                                :#292929;
    --silver-950                                                :#141414;
    /* * Utility / Green */
    --green-25                                                  :#FAFEF5;
    --green-50                                                  :#F3FEE7;
    --green-100                                                 :#E3FBCC;
    --green-200                                                 :#D0F8AB;
    --green-300                                                 :#A6EF67;
    --green-400                                                 :#85E13A;
    --green-500                                                 :#66C61C;
    --green-600                                                 :#4CA30D;
    --green-700                                                 :#3B7C0F;
    --green-800                                                 :#326212;
    --green-900                                                 :#2B5314;
    --green-950                                                 :#15290A;
    /* * Utility / Esmerald */
    --esmerald-25                                               :#F6FEF9;
    --esmerald-50                                               :#EDFCF2;
    --esmerald-100                                              :#D3F8DF;
    --esmerald-200                                              :#AAF0C4;
    --esmerald-300                                              :#73E2A3;
    --esmerald-400                                              :#3CCB7F;
    --esmerald-500                                              :#16B364;
    --esmerald-600                                              :#099250;
    --esmerald-700                                              :#087443;
    --esmerald-800                                              :#095C37;
    --esmerald-900                                              :#084C2E;
    --esmerald-950                                              :#084C2E;
    /* * Utility / Blue */
    --blue-25                                                   :#F5FAFF;
    --blue-50                                                   :#EFF8FF;
    --blue-100                                                  :#D1E9FF;
    --blue-200                                                  :#B2DDFF;
    --blue-300                                                  :#84CAFF;
    --blue-400                                                  :#53B1FD;
    --blue-500                                                  :#2E90FA;
    --blue-600                                                  :#1570EF;
    --blue-700                                                  :#175CD3;
    --blue-800                                                  :#1849A9;
    --blue-900                                                  :#194185;
    --blue-950                                                  :#102A56;
    /* * Utility / Indigo */
    --indigo-25                                                 :#F5F8FF;
    --indigo-50                                                 :#EEF4FF;
    --indigo-100                                                :#E0EAFF;
    --indigo-200                                                :#C7D7FE;
    --indigo-300                                                :#A4BCFD;
    --indigo-400                                                :#8098F9;
    --indigo-500                                                :#6172F3;
    --indigo-600                                                :#444CE7;
    --indigo-700                                                :#3538CD;
    --indigo-800                                                :#2D31A6;
    --indigo-900                                                :#2D3282;
    --indigo-950                                                :#1F235B;
    /* * Utility / Purple */
    --purple-25                                                 :#FAFAFF;
    --purple-50                                                 :#F4F3FF;
    --purple-50                                                 :#F4F3FF;
    --purple-100                                                :#EBE9FE;
    --purple-200                                                :#D9D6FE;
    --purple-300                                                :#BDB4FE;
    --purple-400                                                :#9B8AFB;
    --purple-500                                                :#7A5AF8;
    --purple-600                                                :#6938EF;
    --purple-700                                                :#5925DC;
    --purple-800                                                :#4A1FB8;
    --purple-900                                                :#3E1C96;
    --purple-950                                                :#27115F;
    /* * Utility / Fuschia */
    --fuschia-25                                                :#FEFAFF;
    --fuschia-50                                                :#FDF4FF;
    --fuschia-100                                               :#FBE8FF;
    --fuschia-200                                               :#F6D0FE;
    --fuschia-300                                               :#EEAAFD;
    --fuschia-400                                               :#E478FA;
    --fuschia-500                                               :#D444F1;
    --fuschia-600                                               :#BA24D5;
    --fuschia-700                                               :#9F1AB1;
    --fuschia-800                                               :#821890;
    --fuschia-900                                               :#6F1877;
    --fuschia-950                                               :#47104C;
    /* * Utility / Yellow */
    --yellow-25                                                 :#FEFBE8;
    --yellow-50                                                 :#FEFBE8;
    --yellow-100                                                :#FEF7C3;
    --yellow-200                                                :#FEEE95;
    --yellow-300                                                :#FDE272;
    --yellow-400                                                :#FAC515;
    --yellow-500                                                :#EAAA08;
    --yellow-600                                                :#CA8504;
    --yellow-700                                                :#A15C07;
    --yellow-800                                                :#854A0E;
    --yellow-900                                                :#713B12;
    --yellow-950                                                :#542C0D;

    /* ? Numeric values --------------> */
    /* ? Radius */
    --radius-xs                                                 :.125rem;
    --radius-sm                                                 :.25rem;
    --radius-md                                                 :.5rem;
    --radius-lg                                                 :.75rem;
    --radius-xl                                                 :1rem;
    --radius-full                                               :6.1875rem;
    /* ? Spacing */
    --spacing-0                                                 :0rem;
    --spacing-1                                                 :.125rem;
    --spacing-2                                                 :.25rem;
    --spacing-3                                                 :.5rem;
    --spacing-4                                                 :.75rem;
    --spacing-5                                                 :1rem;
    --spacing-6                                                 :1.5rem;
    --spacing-7                                                 :2rem;
    --spacing-8                                                 :2.5rem;
    --spacing-9                                                 :3rem;
    --spacing-10                                                :3.5rem;
    --spacing-11                                                :4rem;
    --spacing-12                                                :4.5rem;
    --spacing-13                                                :5rem;
    --spacing-14                                                :6rem;
    --spacing-15                                                :6.5rem;
    --spacing-16                                                :7.5rem;
    --spacing-17                                                :10rem;
    --dashboard-gap                                             :var(--spacing-6);

    /* ? Icons */
    --size-icon-xs                                              :1rem;
    --size-icon-sm                                              :1.25rem;
    --size-icon-md                                              :1.5625rem;
    --size-icon-lg                                              :2rem;

    /* ? TOKENS -------------------------------------------------------------------->*/
    /* ? Colors --------------> */
    /* ? Backgrounds */
    --color-bg-primary                                          :var(--silver-25);
    --color-bg-secondary                                        :var(--silver-100);
    --color-bg-tertiary                                         :var(--gray-100);
    --color-bg-disabled                                         :var(--silver-100);
    --color-bg-elevation                                        :var(--silver-25);
    --color-bg-focus                                            :var(--silver-800);
    /* ? Foreground */
    --color-fg-primary                                          :var(--gray-900);
    --color-fg-primary-inverted                                 :var(--silver-50);
    --color-fg-secondary                                        :var(--gray-700);
    --color-fg-tertiary                                         :var(--gray-600);
    --color-fg-quaternary                                       :var(--gray-500);
    --color-fg-brand                                            :var(--brand-600);
    --color-fg-disabled                                         :var(--gray-400);
    --color-fg-link                                             :var(--blue-700);
    --color-fg-danger                                           :var(--danger-600);
    --color-fg-warning                                          :var(--warning-600);
    /* ? Border */
    --color-border-brand                                        :var(--brand-600);
    --color-border-enabled                                      :var(--gray-300);
    --color-border-hover                                        :var(--gray-400);
    --color-border-active                                       :var(--gray-600);
    --color-border-disabled                                     :var(--gray-300);
    --color-border-danger-enabled                               :var(--danger-300);
    --color-border-danger-hover                                 :var(--danger-400);
    --color-border-danger-active                                :var(--danger-600);
    --color-border-warning-enabled                              :var(--warning-300);
    --color-border-warning-hover                                :var(--warning-400);
    --color-border-warning-active                               :var(--warning-600);
    --color-divider                                             :var(--gray-200);
	--color-selected 											:var(--silver-100);
    /* ? Buttons */
    /* * Buttons / Primary */
    --color-btn-primary-enabled                                 :var(--brand-600);
    --color-btn-primary-hover                                   :var(--brand-700);
    --color-btn-primary-active                                  :var(--brand-800);
    /* * Buttons / Secondary */
    --color-btn-secondary-hover                                 :var(--gray-100);
    --color-btn-secondary-active                                :var(--gray-200);
    /* * Buttons / Tertiary */
    --color-btn-tertiary-hover                                  :var(--gray-100);
    --color-btn-tertiary-active                                 :var(--gray-200);
    /* * Buttons / Link */
    --color-btn-link-hover                                      :var(--blue-700);
    --color-btn-link-active                                     :var(--blue-800);
    /* * Buttons / Danger / Primary */
    --color-btn-primary-danger-enabled                          :var(--danger-600);
    --color-btn-primary-danger-hover                            :var(--danger-700);
    --color-btn-primary-danger-active                           :var(--danger-800);
    /* * Buttons / Danger / Secondary */
    --color-btn-primary-danger-hover                            :var(--danger-50);
    --color-btn-primary-danger-active                           :var(--danger-200);
    /* * Buttons / Warning / Primary */
    --color-btn-primary-warning-enabled                         :var(--warning-600);
    --color-btn-primary-warning-hover                           :var(--warning-700);
    --color-btn-primary-warning-active                          :var(--warning-800);
    /* * Buttons / Warning / Secondary */
    --color-btn-secondary-warning-hover                         :var(--warning-50);
    --color-btn-secondary-warning-active                        :var(--warning-200);
    /* ? Chips */
    /* * Chips / Brand */
    --color-chip-bg-brand-active                                :var(--brand-50);
    --color-chip-outline-gray                                   :var(--brand-500);
    --color-chip-fg-gray                                        :var(--brand-600);
    /* * Chips / Silver */
    --color-chip-bg-gray                                        :var(--silver-50);
    --color-chip-outline-gray                                   :var(--silver-300);
    --color-chip-fg-gray                                        :var(--silver-700);
    /* * Chips / Green */
    --color-chip-bg-green                                       :var(--green-50);
    --color-chip-outline-green                                  :var(--green-200);
    --color-chip-fg-green                                       :var(--green-700);
    /* * Chips / Esmerald */
    --color-chip-bg-esmerald                                    :var(--esmerald-50);
    --color-chip-outline-esmerald                               :var(--esmerald-200);
    --color-chip-fg-esmerald                                    :var(--esmerald-700);
    /* * Chips / Blue */
    --color-chip-bg-blue                                        :var(--blue-50);
    --color-chip-outline-blue                                   :var(--blue-200);
    --color-chip-fg-blue                                        :var(--blue-700);
    /* * Chips / Indigo */
    --color-chip-bg-indigo                                      :var(--indigo-50);
    --color-chip-outline-indigo                                 :var(--indigo-200);
    --color-chip-fg-indigo                                      :var(--indigo-700);
    /* * Chips / Purple */
    --color-chip-bg-purple                                      :var(--purple-50);
    --color-chip-outline-purple                                 :var(--purple-200);
    --color-chip-fg-purple                                      :var(--purple-700);
    /* * Chips / Fuschia */
    --color-chip-bg-fuschia                                     :var(--fuschia-50);
    --color-chip-outline-fuschia                                :var(--fuschia-200);
    --color-chip-fg-fuschia                                     :var(--fuschia-700);
    /* * Chips / Red */
    --color-chip-bg-red                                         :var(--danger-50);
    --color-chip-outline-red                                    :var(--danger-200);
    --color-chip-fg-red                                         :var(--danger-700);
    /* * Chips / Orange */
    --color-chip-bg-orange                                      :var(--warning-50);
    --color-chip-outline-orange                                 :var(--warning-200);
    --color-chip-fg-orange                                      :var(--warning-700);
    /* ? System */
    /* * System / Success */
    --color-system-success-100                                  :var(--success-200);
    --color-system-success-200                                  :var(--success-400);
    --color-system-success-300                                  :var(--success-600);
    /* * System / Error */
    --color-system-error-100                                    :var(--danger-200);
    --color-system-error-200                                    :var(--danger-400);
    --color-system-error-300                                    :var(--danger-600);
    /* * System / Warning */
    --color-system-warning-100                                  :var(--warning-200);
    --color-system-warning-200                                  :var(--warning-400);
    --color-system-warning-300                                  :var(--warning-600);
    /* * System / Informative */
    --color-system-info-100                                     :var(--blue-200);
    --color-system-info-200                                     :var(--info-400);
    --color-system-info-300                                     :var(--blue-600);
	/* *Charts */
	--charts-color-chart-disable								:var(--silver-200);
	--charts-color-chart-100 									:var(--blue-100);
	--charts-color-chart-200 									:var(--blue-200);
	--charts-color-chart-300 									:var(--blue-300);
	--charts-color-chart-500 									:var(--blue-500);
	--charts-color-chart-600 									:var(--blue-600);
	--charts-color-chart-700 									:var(--blue-700);
	--charts-color-chart-800 									:var(--blue-800);
	--charts-color-chart-900 									:var(--blue-900);
    /* ? Utilities */
    /* * Utilities / Tables and charts */
    --color-score                                               :var(--yellow-500);
    --color-trend-up                                            :var(--success-600);
    --color-trend-down                                          :var(--danger-600);
    --color-chart-100                                           :var(--blue-100);
    --color-chart-200                                           :var(--blue-200);
    --color-chart-300                                           :var(--blue-300);
    --color-chart-400                                           :var(--blue-400);
    --color-chart-500                                           :var(--blue-500);
    --color-chart-600                                           :var(--blue-600);
    --color-chart-700                                           :var(--blue-700);
    --color-chart-800                                           :var(--blue-800);
    --color-chart-900                                           :var(--blue-900);
    /* * Utilities / Locker scheme */
    --color-locker-fill                                         :#2F2F2F;
    --color-locker-stroke                                       :#000000;
    --color-defective                                           :#ED1F2D;
    --color-occupied                                            :#DE8C00;
    --color-open                                                :#FFFFFF;
    --color-expired                                             :#FDF300;
    --color-schedule                                            :#AB1EC2;

    /*? TEXT STYLES -------------------------------------------------------------------->*/
    /* * Font Family */
    --primary-font                                              :'Inter', sans-serif;
    /* ? Font sizes */
    /* * Big Headlines */
    --font-bh1                                                  : 4em;
    --font-bh2                                                  : 3em;
    --font-bh3                                                  : 2.25em;
    /* * Headlines */
    --font-h1                                                   : 1.5em;
    --font-h2                                                   : 1.125em;
    --font-h3                                                   : 1em;
    /* * Body */
    --font-b1                                                   : .875em;
    /* * Captions */
    --font-c1                                                   : .8125em;
    --font-c2                                                   : .75em;
    --font-c3                                                   : .625em;
    /* * Weight | Font  */
    --font-regular                                              : 400;
    --font-medium                                               : 500;
    --font-semibold                                             : 600;
    --font-bold                                                 : 700;
    --font-extrabold                                            : 800;

    /*? HELPERS ------------------------------------------------------------------> */
    /* * Dashboard size */
    --dashboard-width                                           : 82.45%;
    /* * Sidebar Width */
    --sidebar-width                                             : 16rem;
    /* * Auth Form */
    --auth-form                                                 : 28.125rem;

    /* ? TRANSITIONS & ANIMATIONS --------------------------------------------------> */
    --transition-bg-color                                                               : background-color .2s ease-in-out;
	--icon-chevron-next                             :url('../icons/icon-chevron-next.svg') no-repeat;
    --icon-chevron-prev                             :url('../icons/icon-chevron-prev.svg') no-repeat;
	--icon-chevron-next-disabled                    :url('../icons/icon-chevron-next-disabled.svg') no-repeat;
    --icon-chevron-prev-disabled                    :url('../icons/icon-chevron-prev-disabled.svg') no-repeat;
}
.dark-mode {
	/* ? PRIMITIVES -------------------------------------------------------------------->*/
    /* ? Primary */
    /* * Primary / Grey */
    --gray-25                                                   :#FCFCFD;
    --gray-50                                                   :#F9FaFB;
    --gray-100                                                  :#F2F4F7;
    --gray-200                                                  :#EAECF0;
    --gray-300                                                  :#D0D5DD;
    --gray-400                                                  :#98A2B3;
    --gray-500                                                  :#667085;
    --gray-600                                                  :#475467;
    --gray-700                                                  :#344054;
    --gray-800                                                  :#182230;
    --gray-900                                                  :#101828;
    --gray-950                                                  :#0C111D;
    /* * Primary / Brand */
    --brand-25                                                  :#EEF4FB;
    --brand-50                                                  :#E9F1FA;
    --brand-100                                                 :#BBD3EF;
    --brand-200                                                 :#9BBEE7;
    --brand-300                                                 :#6DA1DC;
    --brand-400                                                 :#518ED5;
    --brand-500                                                 :#246DC1;
    --brand-600                                                 :#2269BB;
    --brand-700                                                 :#1B4F8B;
    --brand-800                                                 :#143F70;
    --brand-900                                                 :#123C6B;
    --brand-950                                                 :#051A32;
    /* * Primary / Success */
    --success-25                                                :#F6FEF9;
    --success-50                                                :#ECFDF3;
    --success-100                                               :#DCFAE6;
    --success-200                                               :#A9EFC5;
    --success-300                                               :#75E0A7;
    --success-400                                               :#47CD89;
    --success-500                                               :#17B26A;
    --success-600                                               :#079455;
    --success-700                                               :#067647;
    --success-800                                               :#085D3A;
    --success-900                                               :#074D31;
    --success-950                                               :#053321;
    /* * Primary / Danger */
    --danger-25                                                 :#FFFBFA;
    --danger-50                                                 :#FEF3F2;
    --danger-100                                                :#FEE4E2;
    --danger-200                                                :#FECDCA;
    --danger-300                                                :#FDA29B;
    --danger-400                                                :#F97066;
    --danger-500                                                :#F04438;
    --danger-600                                                :#D92D20;
    --danger-700                                                :#B42318;
    --danger-800                                                :#912018;
    --danger-900                                                :#7A1A1A;
    --danger-950                                                :#55160C;
    /* * Primary / Warning */
    --warning-25                                                :#FFFCF5;
    --warning-50                                                :#FFFAEB;
    --warning-100                                               :#FEF0C7;
    --warning-200                                               :#FEDF89;
    --warning-300                                               :#FEC84B;
    --warning-400                                               :#FDB022;
    --warning-500                                               :#F79009;
    --warning-600                                               :#DC6803;
    --warning-700                                               :#B54708;
    --warning-800                                               :#93370D;
    --warning-900                                               :#7A2E0E;
    --warning-950                                               :#4E1D09;
    /* ? Utility */
    /* * Utility / Silver */
    --silver-25                                                 :#FCFCFC;
    --silver-50                                                 :#FAFAFA;
    --silver-100                                                :#F5F5F5;
    --silver-200                                                :#E5E5E5;
    --silver-300                                                :#D6D6D6;
    --silver-400                                                :#A3A3A3;
    --silver-500                                                :#737373;
    --silver-600                                                :#525252;
    --silver-700                                                :#424242;
    --silver-800                                                :#333333;
    --silver-900                                                :#292929;
    --silver-950                                                :#141414;
    /* * Utility / Green */
    --green-25                                                  :#FAFEF5;
    --green-50                                                  :#F3FEE7;
    --green-100                                                 :#E3FBCC;
    --green-200                                                 :#D0F8AB;
    --green-300                                                 :#A6EF67;
    --green-400                                                 :#85E13A;
    --green-500                                                 :#66C61C;
    --green-600                                                 :#4CA30D;
    --green-700                                                 :#3B7C0F;
    --green-800                                                 :#326212;
    --green-900                                                 :#2B5314;
    --green-950                                                 :#15290A;
    /* * Utility / Esmerald */
    --esmerald-25                                               :#F6FEF9;
    --esmerald-50                                               :#EDFCF2;
    --esmerald-100                                              :#D3F8DF;
    --esmerald-200                                              :#AAF0C4;
    --esmerald-300                                              :#73E2A3;
    --esmerald-400                                              :#3CCB7F;
    --esmerald-500                                              :#16B364;
    --esmerald-600                                              :#099250;
    --esmerald-700                                              :#087443;
    --esmerald-800                                              :#095C37;
    --esmerald-900                                              :#084C2E;
    --esmerald-950                                              :#084C2E;
    /* * Utility / Blue */
    --blue-25                                                   :#F5FAFF;
    --blue-50                                                   :#EFF8FF;
    --blue-100                                                  :#D1E9FF;
    --blue-200                                                  :#B2DDFF;
    --blue-300                                                  :#84CAFF;
    --blue-400                                                  :#53B1FD;
    --blue-500                                                  :#2E90FA;
    --blue-600                                                  :#1570EF;
    --blue-700                                                  :#175CD3;
    --blue-800                                                  :#1849A9;
    --blue-900                                                  :#194185;
    --blue-950                                                  :#102A56;
    /* * Utility / Indigo */
    --indigo-25                                                 :#F5F8FF;
    --indigo-50                                                 :#EEF4FF;
    --indigo-100                                                :#E0EAFF;
    --indigo-200                                                :#C7D7FE;
    --indigo-300                                                :#A4BCFD;
    --indigo-400                                                :#8098F9;
    --indigo-500                                                :#6172F3;
    --indigo-600                                                :#444CE7;
    --indigo-700                                                :#3538CD;
    --indigo-800                                                :#2D31A6;
    --indigo-900                                                :#2D3282;
    --indigo-950                                                :#1F235B;
    /* * Utility / Purple */
    --purple-25                                                 :#FAFAFF;
    --purple-50                                                 :#F4F3FF;
    --purple-50                                                 :#F4F3FF;
    --purple-100                                                :#EBE9FE;
    --purple-200                                                :#D9D6FE;
    --purple-300                                                :#BDB4FE;
    --purple-400                                                :#9B8AFB;
    --purple-500                                                :#7A5AF8;
    --purple-600                                                :#6938EF;
    --purple-700                                                :#5925DC;
    --purple-800                                                :#4A1FB8;
    --purple-900                                                :#3E1C96;
    --purple-950                                                :#27115F;
    /* * Utility / Fuschia */
    --fuschia-25                                                :#FEFAFF;
    --fuschia-50                                                :#FDF4FF;
    --fuschia-100                                               :#FBE8FF;
    --fuschia-200                                               :#F6D0FE;
    --fuschia-300                                               :#EEAAFD;
    --fuschia-400                                               :#E478FA;
    --fuschia-500                                               :#D444F1;
    --fuschia-600                                               :#BA24D5;
    --fuschia-700                                               :#9F1AB1;
    --fuschia-800                                               :#821890;
    --fuschia-900                                               :#6F1877;
    --fuschia-950                                               :#47104C;
    /* * Utility / Yellow */
    --yellow-25                                                 :#FEFBE8;
    --yellow-50                                                 :#FEFBE8;
    --yellow-100                                                :#FEF7C3;
    --yellow-200                                                :#FEEE95;
    --yellow-300                                                :#FDE272;
    --yellow-400                                                :#FAC515;
    --yellow-500                                                :#EAAA08;
    --yellow-600                                                :#CA8504;
    --yellow-700                                                :#A15C07;
    --yellow-800                                                :#854A0E;
    --yellow-900                                                :#713B12;
    --yellow-950                                                :#542C0D;
    /* ? Numeric values --------------> */
    /* ? Radius */
    --radius-xs                                                 :.125rem;
    --radius-sm                                                 :.25rem;
    --radius-md                                                 :.5rem;
    --radius-lg                                                 :.75rem;
    --radius-xl                                                 :1rem;
    --radius-full                                               :6.1875rem;
    /* ? Spacing */
    --spacing-0                                                 :0rem;
    --spacing-1                                                 :.125rem;
    --spacing-2                                                 :.25rem;
    --spacing-3                                                 :.5rem;
    --spacing-4                                                 :.75rem;
    --spacing-5                                                 :1rem;
    --spacing-6                                                 :1.5rem;
    --spacing-7                                                 :2rem;
    --spacing-8                                                 :2.5rem;
    --spacing-9                                                 :3rem;
    --spacing-10                                                :3.5rem;
    --spacing-11                                                :4rem;
    --spacing-12                                                :4.5rem;
    --spacing-13                                                :5rem;
    --spacing-14                                                :6rem;
    --spacing-15                                                :6.5rem;
    --spacing-16                                                :7.5rem;
    --spacing-17                                                :10rem;
    --dashboard-gap                                             :var(--spacing-6);

    /* ? Icons */
    --size-icon-xs                                              :1rem;
    --size-icon-sm                                              :1.25rem;
    --size-icon-md                                              :1.5625rem;
    --size-icon-lg                                              :2rem;

    /* ? TOKENS -------------------------------------------------------------------->*/
    /* ? Colors --------------> */
    /* ? Backgrounds */
    --color-bg-primary                                          :var(--silver-900);
    --color-bg-secondary                                        :var(--silver-800);
    --color-bg-tertiary                                         :var(--silver-700);
    --color-bg-disabled                                         :var(--silver-700);
    --color-bg-elevation                                        :var(--silver-800);
    --color-bg-focus                                            :var(--silver-500);
    /* ? Foreground */
    --color-fg-primary                                          :var(--silver-25);
    --color-fg-primary-inverted                                 :var(--silver-25);
    --color-fg-secondary                                        :var(--silver-200);
    --color-fg-tertiary                                         :var(--silver-300);
    --color-fg-quaternary                                       :var(--silver-400);
    --color-fg-brand                                            :var(--brand-500);
    --color-fg-disabled                                         :var(--silver-500);
    --color-fg-link                                             :var(--blue-400);
    --color-fg-danger                                           :var(--danger-500);
    --color-fg-warning                                          :var(--warning-500);
    /* ? Border */
    --color-border-brand                                        :var(--brand-400);
    --color-border-enabled                                      :var(--silver-400);
    --color-border-hover                                        :var(--silver-300);
    --color-border-active                                       :var(--silver-200);
    --color-border-disabled                                     :var(--silver-600);
    --color-border-danger-enabled                               :var(--danger-500);
    --color-border-danger-hover                                 :var(--danger-400);
    --color-border-danger-active                                :var(--danger-300);
    --color-border-warning-enabled                              :var(--warning-500);
    --color-border-warning-hover                                :var(--warning-400);
    --color-border-warning-active                               :var(--warning-300);
    --color-divider                                             :var(--silver-700);
	--color-selected 											:var(--silver-800);
    /* ? Buttons */
    /* * Buttons / Primary */
    --color-btn-primary-enabled                                 :var(--brand-500);
    --color-btn-primary-hover                                   :var(--brand-400);
    --color-btn-primary-active                                  :var(--brand-300);
    /* * Buttons / Secondary */
    --color-btn-secondary-hover                                 :var(--silver-700);
    --color-btn-secondary-active                                :var(--silver-800);
    /* * Buttons / Tertiary */
    --color-btn-tertiary-hover                                  :var(--silver-700);
    --color-btn-tertiary-active                                 :var(--silver-800);
    /* * Buttons / Link */
    --color-btn-link-hover                                      :var(--blue-700);
    --color-btn-link-active                                     :var(--blue-800);
    /* * Buttons / Danger / Primary */
    --color-btn-primary-danger-enabled                          :var(--danger-700);
    --color-btn-primary-danger-hover                            :var(--danger-600);
    --color-btn-primary-danger-active                           :var(--danger-500);
    /* * Buttons / Danger / Secondary */
    --color-btn-primary-danger-hover                            :var(--danger-900);
    --color-btn-primary-danger-active                           :var(--danger-950);
    /* * Buttons / Warning / Primary */
    --color-btn-primary-warning-enabled                         :var(--warning-700);
    --color-btn-primary-warning-hover                           :var(--warning-600);
    --color-btn-primary-warning-active                          :var(--warning-500);
    /* * Buttons / Warning / Secondary */
    --color-btn-secondary-warning-hover                         :var(--warning-900);
    --color-btn-secondary-warning-active                        :var(--warning-950);
    /* ? Chips */
    /* * Chips / Brand */
    --color-chip-bg-brand-active                                :var(--brand-900);
    --color-chip-outline-brand-active                           :var(--brand-600);
    --color-chip-fg-brand-active                                :var(--brand-200);
    /* * Chips / Silver */
    --color-chip-bg-gray                                        :var(--silver-950);
    --color-chip-outline-gray                                   :var(--silver-700);
    --color-chip-fg-gray                                        :var(--silver-300);
    /* * Chips / Green */
    --color-chip-bg-green                                       :var(--green-950);
    --color-chip-outline-green                                  :var(--green-700);
    --color-chip-fg-green                                       :var(--green-300);
    /* * Chips / Esmerald */
    --color-chip-bg-esmerald                                    :var(--esmerald-950);
    --color-chip-outline-esmerald                               :var(--esmerald-700);
    --color-chip-fg-esmerald                                    :var(--esmerald-300);
    /* * Chips / Blue */
    --color-chip-bg-blue                                        :var(--blue-950);
    --color-chip-outline-blue                                   :var(--blue-700);
    --color-chip-fg-blue                                        :var(--blue-300);
    /* * Chips / Indigo */
    --color-chip-bg-indigo                                      :var(--indigo-950);
    --color-chip-outline-indigo                                 :var(--indigo-700);
    --color-chip-fg-indigo                                      :var(--indigo-300);
    /* * Chips / Purple */
    --color-chip-bg-purple                                      :var(--purple-950);
    --color-chip-outline-purple                                 :var(--purple-700);
    --color-chip-fg-purple                                      :var(--purple-300);
    /* * Chips / Fuschia */
    --color-chip-bg-fuschia                                     :var(--fuschia-950);
    --color-chip-outline-fuschia                                :var(--fuschia-700);
    --color-chip-fg-fuschia                                     :var(--fuschia-300);
    /* * Chips / Red */
    --color-chip-bg-red                                         :var(--danger-950);
    --color-chip-outline-red                                    :var(--danger-700);
    --color-chip-fg-red                                         :var(--danger-300);
    /* * Chips / Orange */
    --color-chip-bg-orange                                      :var(--warning-950);
    --color-chip-outline-orange                                 :var(--warning-700);
    --color-chip-fg-orange                                      :var(--warning-300);
    /* ? System */
    /* * System / Success */
    --color-system-success-100                                  :var(--success-900);
    --color-system-success-200                                  :var(--success-700);
    --color-system-success-300                                  :var(--success-500);
    /* * System / Error */
    --color-system-error-100                                    :var(--danger-900);
    --color-system-error-200                                    :var(--danger-700);
    --color-system-error-300                                    :var(--danger-500);
    /* * System / Warning */
    --color-system-warning-100                                  :var(--warning-900);
    --color-system-warning-200                                  :var(--warning-700);
    --color-system-warning-300                                  :var(--warning-500);
    /* * System / Informative */
    --color-system-info-100                                     :var(--blue-900);
    --color-system-info-200                                     :var(--blue-700);
    --color-system-info-300                                     :var(--blue-500);
    /* ? Utilities */
    /* * Utilities / Tables and charts */
    --color-score                                               :var(--yellow-400);
    --color-trend-up                                            :var(--success-500);
    --color-trend-down                                          :var(--danger-500);
    --color-chart-100                                           :var(--blue-900);
    --color-chart-200                                           :var(--blue-800);
    --color-chart-300                                           :var(--blue-700);
    --color-chart-400                                           :var(--blue-600);
    --color-chart-500                                           :var(--blue-500);
    --color-chart-600                                           :var(--blue-400);
    --color-chart-700                                           :var(--blue-300);
    --color-chart-800                                           :var(--blue-200);
    --color-chart-900                                           :var(--blue-100);
    /* * Utilities / Locker scheme */
    --color-locker-fill                                         :#2F2F2F;
    --color-locker-stroke                                       :#000000;
    --color-defective                                           :#ED1F2D;
    --color-occupied                                            :#DE8C00;
    --color-open                                                :#FFFFFF;
    --color-expired                                             :#FDF300;
    --color-schedule                                            :#AB1EC2;

	/* *Charts */
	--charts-color-chart-disable								:var(--silver-200);
	--charts-color-chart-100 									:var(--blue-100);
	--charts-color-chart-200 									:var(--blue-200);
	--charts-color-chart-300 									:var(--blue-300);
	--charts-color-chart-500 									:var(--blue-500);
	--charts-color-chart-600 									:var(--blue-600);
	--charts-color-chart-700 									:var(--blue-700);
	--charts-color-chart-800 									:var(--blue-800);
	--charts-color-chart-900 									:var(--blue-900);


    /*? TEXT STYLES -------------------------------------------------------------------->*/
    /* * Font Family */
    --primary-font                                              :'Inter', sans-serif;
    /* ? Font sizes */
    /* * Big Headlines */
    --font-bh1                                                  : 4em;
    --font-bh2                                                  : 3em;
    --font-bh3                                                  : 2.25em;
    /* * Headlines */
    --font-h1                                                   : 1.5em;
    --font-h2                                                   : 1.125em;
    --font-h3                                                   : 1em;
    /* * Body */
    --font-b1                                                   : .875em;
    /* * Captions */
    --font-c1                                                   : .8125em;
    --font-c2                                                   : .75em;
    --font-c3                                                   : .625em;
    /* * Weight | Font  */
    --font-regular                                              : 400;
    --font-medium                                               : 500;
    --font-semibold                                             : 600;
    --font-bold                                                 : 700;
    --font-extrabold                                            : 800;

    /*? HELPERS ------------------------------------------------------------------> */
    /* * Dashboard size */
    --dashboard-width                                           : 82.45%;
    /* * Sidebar Width */
    --sidebar-width                                             : 16rem;
    /* * Auth Form */
    --auth-form                                                 : 28.125rem;

    /* ? TRANSITIONS & ANIMATIONS --------------------------------------------------> */
    --transition-bg-color                                                               : background-color .2s ease-in-out;
	--icon-chevron-next                             :url('../icons/dark-mode-icons/icon-chevron-next-dark.svg') no-repeat;
    --icon-chevron-prev                             :url('../icons/dark-mode-icons/icon-chevron-prev-dark.svg') no-repeat;
	--icon-chevron-next-disabled                    :url('../icons/dark-mode-icons/icon-chevron-next-dark.svg') no-repeat;
    --icon-chevron-prev-disabled                    :url('../icons/dark-mode-icons/icon-chevron-prev-dark.svg') no-repeat;
}
