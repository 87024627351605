// VARIABLES
@import './assets/styles/var-styles.css';
@import './assets/styles/variableDesignSystem.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

/*? GLOBAL STYLES ----------------------------------> */
body {
    background-color: var(--color-bg-secondary);
}
// GLOBAL FONT
* {
    margin: 0;
    padding: 0;
    font-family: var(--primary-font);
	scrollbar-width: thin;
	scrollbar-color: var(--color-primary-400) none;
}

p, input, ul {
    padding: 0;
    margin: 0;
}

