/* Cambiar el fondo y el texto de la notificación de éxito */
.toast-extendida {
    color: #212121;
    font-size: 14px;
    font-weight: 600 !important;
    background-color: #f1fff2;
    border: solid 1.5px #39a94e;
    width: 376px;
    height: 60px;
    border-radius: 8px !important;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 12px 16px 12px 58px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 36px;
    box-shadow: none;
    background-image: url('../images/icono-toast-mediano.svg');
	margin-left: 19rem;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #39a94e;
    opacity: 1;
}

.toast-title {
    color: #212121;
    font-size: 13px;
    font-weight: 600 !important;
    font-family: var(--primary-font);
    text-align: initial;
    margin-top: -3px;
    margin-left: 5px;
    /* font-weight: 700; */
}

.toast-message {
    word-wrap: break-word;
    /* color: #212121; */
    color: #4b4b4b;
    font-family: var(--primary-font);
    font-size: 12px;
    font-weight: 500;
    text-align: initial;
	line-height: 18px;
    margin-top: 4px;
    margin-left: 5px;
}
.toast-bottom-center {
    bottom: 32px;
    width: 100%;
    text-align: -webkit-center;
}

.toast-top-center {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 43rem;
}

.toast-exitosa {
    color: #212121;
    font-size: 14px;
    font-weight: 600 !important;
    background-color: #f1fff2;
    border: solid 1.5px #39a94e;
    width: 376px;
    height: 60px;
    border-radius: 8px !important;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px 19rem;
    padding: 12px 25px 12px 58px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 36px;
    box-shadow: none;
    background-image: url('../images/icono-toast-mediano.svg');
}

.toast-erronea {
    color: #212121;
    font-size: 14px;
    font-weight: 600 !important;
    background-color: #fff1f1;
    border: solid 1.5px #a93939;
    border-radius: 8px !important;
    position: relative;
    overflow: hidden;
    padding: 12px 16px 12px 58px;
    width: 376px;
	height: 66px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 36px;
    box-shadow: none;
    background-image: url('../images/toast-error.svg');
	margin-left: 19rem;
	margin-top: 1.5rem;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #a93939;
    opacity: 1;
}

.toast-error-toggle {
    color: #212121;
    font-size: 14px;
    font-weight: 600 !important;
    background-color: #fff1f1;
    border: solid 1.5px #a93939;
    border-radius: 8px !important;
    position: relative;
    overflow: hidden;
    padding: 12px 16px 12px 58px;
    width: 376px;
	height: 66px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 36px;
    box-shadow: none;
    background-image: url('../images/toast-error.svg');
	margin-left: 19rem;
	margin-top: 1.5rem;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #a93939;
    opacity: 1;
}

.toast-error {
    color: #212121;
    font-size: 14px;
    font-weight: 600 !important;
    background-color: #fff1f1;
    border: solid 1.5px #a93939;
    border-radius: 8px !important;
    position: relative;
    overflow: hidden;
    padding: 12px 16px 12px 58px;
    width: 376px;
	height: 66px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 36px;
    box-shadow: none;
    background-image: url('../images/toast-error.svg');
	margin-left: 19rem;
	margin-top: 1.5rem;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #a93939;
    opacity: 1;
}

.toast-warn {
    color: #212121;
    font-size: 14px;
    font-weight: 600 !important;
    background-color: #fff9f1;
    border: solid 1.5px #C17706;
    border-radius: 8px !important;
    position: relative;
    overflow: hidden;
    padding: 12px 16px 12px 58px;
    width: 376px;
	height: 66px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 36px;
    box-shadow: none;
    background-image: url('../images/toast-info.svg');
	margin-left: 19rem;
	margin-top: 1.5rem;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #C17706;
    opacity: 1;
}

.toast-ok{
    color: #212121;
    font-size: 14px;
    font-weight: 600 !important;
    background-color: #FAFAFA;
    border: solid 1.5px #D0D5DD;
    width: 376px;
    height: auto;
    border-radius: 8px !important;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr;
    gin: 0 0 6px;
    padding: 12px 16px 12px 48px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 26px;
    box-shadow: none;
	background-image: url('../images/toast-ok-warehouse.svg');
    align-items: center;
    display: grid;
}
.toast-error-timeout{
	color: #212121;
	font-size: 14px;
	font-weight: 600 !important;
	background-color: #FAFAFA;
	border: solid 1.5px #D0D5DD;
    width: 376px;
	height: auto;
	border-radius: 8px !important;
	position: relative;
	overflow: hidden;
	margin: 0px 0px 6px 0px;
	padding: 12px 16px 12px 38px;
	background-position: 15px center;
	background-repeat: no-repeat;
	background-size: 16px;
	box-shadow: none;
	background-image: url('../images/toast-error-warehouse.svg');
	margin-left: 0rem;
	align-items: center;
	display: block;
	margin-left: 19rem;
	margin-top: 1.5rem;
}

.toast-close-button {
    /* position: relative; */
    right: 1rem;
    top: 8px;
    float: right;
    font-size: 20px;
    font-weight: 100;
    color: #667085;
    /* text-shadow: 0 1px 0 red; */
    /* opacity: 0.8; */
    display: flex;
    position: absolute;
}




/* * Responsive SM */
@media screen and (max-width:480px) {
	.toast-ok {
		color: #212121;
		font-size: 14px;
		font-weight: 600 !important;
		background-color: #FAFAFA;
		border: solid 1.5px #D0D5DD;
		width: 316px;
		height: 51px;
		border-radius: 8px !important;
		position: relative;
		overflow: hidden;
		margin: 0px 0px 6px 0px;
		padding: 12px 16px 12px 38px;
		background-position: 15px center;
		background-repeat: no-repeat;
		background-size: 16px;
		box-shadow: none;
		background-image: url('../images/toast-ok-warehouse.svg');
		margin-left: 0rem;
		align-items: center;
		display: flex;
	}

    .toast-close-button {
        /* position: relative; */
        right: 1rem;
        top: 8px;
        float: right;
        font-size: 20px;
        font-weight: 100;
        color: #667085;
        /* text-shadow: 0 1px 0 red; */
        /* opacity: 0.8; */
        display: flex;
        position: absolute;
    }
}
/* * Responsive MD */
@media screen and (min-width:481px) and (max-width:1024px) {
	.toast-ok {
		color: #212121;
		font-size: 14px;
		font-weight: 600 !important;
		background-color: #FAFAFA;
		border: solid 1.5px #D0D5DD;
		width: 316px;
		height: 51px;
		border-radius: 8px !important;
		position: relative;
		overflow: hidden;
		margin: 0px 0px 6px 0px;
		padding: 12px 16px 12px 38px;
		background-position: 15px center;
		background-repeat: no-repeat;
		background-size: 16px;
		box-shadow: none;
		background-image: url('../images/toast-ok-warehouse.svg');
		margin-left: 0rem;
		align-items: center;
		display: flex;
	}

    .toast-close-button {
        /* position: relative; */
        right: 1rem;
        top: 8px;
        float: right;
        font-size: 20px;
        font-weight: 100;
        color: #667085;
        /* text-shadow: 0 1px 0 red; */
        /* opacity: 0.8; */
        display: flex;
        position: absolute;
    }
}
